function populateDatalayer() {
  if (process.browser) {
    window.dataLayer = [
      {
        user_name: undefined,
        user_email: undefined,
      },
    ];
  }
}

function getFunctionBody(func) {
  const entire = func.toString();
  const body = entire.substring(
    entire.indexOf('{') + 1,
    entire.lastIndexOf('}'),
  );
  return body;
}

function setDatalayer() {
  return getFunctionBody(populateDatalayer);
}

export default setDatalayer;
