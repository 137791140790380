import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  styled,
} from '@mui/material';
import xw from 'xwind';
import { css } from '@emotion/react';

export const FormModalWrapper = styled(Box)<{ isMinHeight: boolean }>`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  color: ${({ theme }) => theme.palette.grey[700]};
  width: ${({ theme }) => theme.spacing(175)};
  padding: ${({ theme }) => theme.spacing(4)};
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
  overflow: auto;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: auto;
    padding: ${({ theme }) => theme.spacing(6)};
    min-height: ${({ theme, isMinHeight }) =>
      isMinHeight ? theme.spacing(142) : 'auto'};
    max-height: 100%;
  }
`;

export const FormModalTitle = styled(Stack)(
  xw`text-lg font-bold p-0 sm:leading-tight text-4xl`,
  css`
    max-width: calc(100% - 36px);
  `,
);

export const FormModalContent = styled(DialogContent)(
  xw`p-0 pt-5 flex-initial sm[flex-auto]`,
);

export const FormModalActions = styled(DialogActions)(
  xw`p-0 flex-wrap gap-2 pt-6`,
  {
    '& > *': {
      marginLeft: '0 !important',
    },
  },
);
