/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prettier/prettier */
function isEmpty(obj) {
  for (const x in obj) {
    return false;
  }
  return true;
}

export default isEmpty;
