import { NextRouter } from 'next/router';
import { HOME_PATH } from '@/common/constants/routes';

export default async function goToHowItWorks(
  router: NextRouter,
): Promise<void> {
  await router.push(HOME_PATH);

  const pageHeader = document.getElementById('page-header');
  const heroElement = document.getElementById('unauthorized-hero');

  const { height: headerHeight } = pageHeader?.getBoundingClientRect() ?? {};
  const { height } = heroElement?.getBoundingClientRect() ?? {};

  if (headerHeight && height && heroElement) {
    window.scrollTo({
      top: heroElement.offsetTop + height - headerHeight,
    });
  }
}
