import { DEFAULT_ERROR_MESSAGE } from '@/common/constants/error-messages';

function parseServerError(error): string[] | null {
  console.log(JSON.stringify(error));
  if (Array.isArray(error)) {
    return error;
  }
  if (error?.message) {
    return [error.message];
  }
  if (typeof error === 'object') {
    return Object.values(error);
  }
  if (error) {
    return [JSON.stringify(error)/*DEFAULT_ERROR_MESSAGE*/];
  }

  return null;
}

export default parseServerError;
