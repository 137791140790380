import { format } from 'date-fns';

export function dateDiff(dateTime: string): string {
  if (!dateTime) return null;

  const now = new Date();
  const publishingTime = new Date(dateTime);

  let displayDate = '・ ';
  const years = now.getFullYear() - publishingTime.getFullYear();
  const months = years * 12 + now.getMonth() - publishingTime.getMonth() - 1;
  const days = Math.round(
    (now.valueOf() - publishingTime.valueOf()) / (1000 * 60 * 60 * 24),
  );
  let hours = publishingTime.getHours();
  const minutes = publishingTime.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutesStr}  ${ampm}`;

  if (days < 1) displayDate = `Today at ${strTime}`;
  if (days === 1) displayDate = `Yesterday at ${strTime}`;
  if (days >= 2) displayDate = `${days} days ago at ${strTime}`;
  if (months === 1) displayDate = `1 month ago at ${strTime}`;
  if (months > 1) displayDate = `${months} months ago at ${strTime}`;
  if (years > 1)
    displayDate += `${format(
      new Date(dateTime),
      'MMMM dd, yyyy',
    )} at ${strTime}`;

  return displayDate;
}

export const dateFormatShort = 'MMM dd, yyyy';
function renderPublishedDate(
  publishedAt: string,
  formatStr = 'MMMM dd, yyyy',
): JSX.Element {
  if (!publishedAt) return null;

  const formatedDate = format(new Date(publishedAt), formatStr);

  return (
    <span>
      <time dateTime={publishedAt}>{formatedDate}</time>
    </span>
  );
}

export default renderPublishedDate;
