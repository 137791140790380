import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Icon from '@/components/icons/Icon';
import { CloseButton } from '@/widgets/Modal/Modal.styles';
import { FormModalTitle, FormModalWrapper } from './FormModal.styles';

export interface IFormModalProps extends Partial<Omit<DialogProps, 'title'>> {
  title?: React.ReactNode;
  isMinHeight?: boolean;
}

const FormModal = ({
  title,
  children,
  onClose,
  open,
  isMinHeight,
  ...props
}: IFormModalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(open || false);
  const theme = useTheme();
  const matches = useMediaQuery(
    `(min-width: ${theme.breakpoints.values.sm}px)`,
  );

  const handleClose = useCallback(
    (event: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
      setIsModalOpen(false);
      if (onClose) {
        onClose(event, reason);
      }
    },
    [onClose],
  );

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      {...props}
      open={isModalOpen}
      fullScreen={props.fullScreen || !matches}
    >
      <CloseButton onClick={(e) => handleClose(e, 'escapeKeyDown')}>
        <Icon iconName="times" />
      </CloseButton>
      <FormModalWrapper isMinHeight={isMinHeight}>
        {!!title && (
          <FormModalTitle
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {typeof title !== 'object' ? (
              <Typography variant="h3">{title}</Typography>
            ) : (
              title
            )}
          </FormModalTitle>
        )}
        {children}
      </FormModalWrapper>
    </Dialog>
  );
};

FormModal.defaultProps = {
  title: '',
  isMinHeight: false,
};

export default FormModal;
