import getSymbolFromCurrencyMap from 'currency-symbol-map';

function getSymbolFromCurrency(currency?: string): string {
  const defaultSymbol = '$';
  if (!currency) return defaultSymbol;

  const symbol = getSymbolFromCurrencyMap(currency);
  return symbol ?? defaultSymbol;
}

export default getSymbolFromCurrency;
